import React, {useEffect,  Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";
import NavScrollTop from './components/NavScrollTop';
const HomeThree = lazy(() => import("./pages/HomeThree"));
const About = lazy(() => import("./pages/About"));
const Service = lazy(() => import("./pages/Service"));
const WorkDetails = lazy(() => import("./pages/WorkDetails"));
/*const BlogGrid = lazy(() => import("./pages/BlogGrid"));
const BlogClassic = lazy(() => import("./pages/BlogClassic"));
const BlogCategories = lazy(() => import("./pages/BlogCategories"));
const BlogTag = lazy(() => import("./pages/BlogTag"));*/
const Contact = lazy(() => import("./pages/Contact"));
const Financing = lazy(() => import("./pages/Financing"));
const Book = lazy(() => import("./pages/Book"));
const ServiceAreas = lazy(() => import("./pages/ServiceAreas"));
const ServiceArea = lazy(() => import("./pages/ServiceArea"));
import ServiceAreaData from "./data/service-areas/service-area.json";
import ServiceData from "./data/services/services.json";
//import BlogClassic from "./pages/BlogClassic";
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const AccessibilityStatement = lazy(() => import("./pages/AccessibilityStatement"));
const Work = lazy(() => import('./pages/Work'));
//const BlogDetails = lazy(() => import('./pages/BlogDetails'));
const Testimonials = lazy(() => import('./pages/Testimonials'));
const ServiceDetails = lazy(() => import('./pages/ServiceDetails'));
//const Products = lazy(() => import('./pages/Product'));
//const ProductDetails = lazy(() => import('./pages/ProductDetails'));
//import ProductData from "./data/products/products.json";

function App() {
  useEffect(() => {
    AOS.init({
        offset: 80,
        duration: 1000,
        once: true,
        easing: 'ease',
    });
    AOS.refresh();
    
  }, [])
    const stateMap = {
        'VA': 'virginia',
        'MD': 'maryland',
        'DC': 'dc'
    };
  const router = <Router>
      <NavScrollTop>
          <Suspense fallback={<div />}>
              <Routes>
                  <Route path={`${process.env.PUBLIC_URL}`} element={<HomeThree/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/"}`} element={<HomeThree/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/financing"}`} element={<Financing/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/about"}`} element={<About/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/book"}`} element={<Book/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/service-areas"}`} element={<ServiceAreas/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/services"}`} element={<Service/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/gallery"}`} element={<Work/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/projects/:id"}`} element={<WorkDetails/>} />
                  {/*<Route path={`${process.env.PUBLIC_URL + "/blog-grid"}`} element={<BlogGrid/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/blog-classic"}`} element={<BlogClassic/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/types-of-insulation"}`} element={<Products/>} />

                  <Route path={`${process.env.PUBLIC_URL + "/tag/:slug"}`} element={<BlogTag/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/category/:slug"}`} element={<BlogCategories/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`}element={<BlogDetails/>} />*/}
                  <Route path={`${process.env.PUBLIC_URL + "/contact"}`} element={<Contact/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/privacy-policy"}`} element={<PrivacyPolicy/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/accessibility-statement"}`} element={<AccessibilityStatement/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/testimonials"}`} element={<Testimonials/>} />

                  {ServiceAreaData && ServiceAreaData.map((single,) => {
                      const city = single.city;
                      const state = single.state;
                      const linkText = `${city.replace(' ', '-')}-${stateMap[state]}`.toLowerCase();
                      const url = `${process.env.PUBLIC_URL + "/" + linkText}`;

                      return(
                          <Route path={url} element={<ServiceArea/>} />
                      );
                  })}
                  {ServiceData && ServiceData.map((single,) => {
                      const url = `${process.env.PUBLIC_URL + "/" + single.link}`;

                      return(
                          <Route path={url} element={<ServiceDetails/>} />
                      );
                  })}
                  {/*{ProductData && ProductData.map((single,) => {
                      const url = `${process.env.PUBLIC_URL + "/" + single.link}`;

                      return(
                          <Route path={url} element={<ProductDetails/>} />
                      );
                  })}*/}
              </Routes>
          </Suspense>
      </NavScrollTop>
  </Router>;
    /*const urls = [];
    router.props.children.props.children.props.children.props.children.forEach(child => {
        if (Array.isArray(child)) {
            child.forEach(item => getPath(item, urls))
        } else {
            getPath(child, urls)
        }

    });
    const sitemap =
        `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
            ${urls.join('\n')}
        </urlset>`;
    console.log(sitemap);*/
  return router;
}

/*const getPath = function (obj, urls) {
    if (obj.props !== undefined && obj.props.path !== undefined) {
        urls.push(
            `<url>
                <loc>https://foundersinsulation.com${obj.props.path}</loc>
                <lastmod>${new Date(Date.now()).toISOString()}</lastmod>
             </url>`
        );
    }
}*/

export default App;
